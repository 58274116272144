import React, { forwardRef } from "react";

import NavLink from "components/NavLink";
import { connect } from "react-redux";
import { selectPlatformSourceFromApp } from "store/app/selectors";
import { createStructuredSelector } from "reselect";

const PropertyItemProto = forwardRef(
    (
        {
            tileHoverAnimation = true,
            image,
            imageText = "",
            onClick = () => {},
            className = "",
            children,
            isVideo = false,
            videoPath,
            dataAsset,
            dataStatus,
            isNotLink,
            href,
            as,
            isApp,
        },
        reference,
    ) => {
        return (
            <>
                {isNotLink ? (
                    <div
                        className={`flex column item-block shadow border-radius property-item ${
                            tileHoverAnimation ? "tile-hover-effect" : ""
                        } ${className}`}
                        onClick={onClick}
                        onKeyPress={onClick}
                        tabIndex={0}
                        role="button"
                        ref={reference}
                        data-asset={dataAsset}
                        data-status={dataStatus}
                    >
                        <div className="image">
                            <span className="text-12 tag fw-600">{imageText}</span>
                            <img src={isVideo && !isApp ? videoPath : image} alt="Product" />
                        </div>
                        {typeof children === "function" ? children() : children}
                    </div>
                ) : (
                    <NavLink
                        className={`flex column item-block shadow border-radius property-item ${
                            tileHoverAnimation ? "tile-hover-effect" : ""
                        } ${className}`}
                        href={href}
                        as={as}
                        onClick={onClick}
                        onKeyPress={onClick}
                        tabIndex={0}
                        role="button"
                        ref={reference}
                        dataAsset={dataAsset}
                        dataStatus={dataStatus}
                    >
                        <div className="image">
                            <span className="text-12 tag fw-600">{imageText}</span>
                            <img src={isVideo && !isApp ? videoPath : image} alt="Product" />
                        </div>
                        {typeof children === "function" ? children() : children}
                    </NavLink>
                )}
            </>
        );
    },
);

const mapStateToProps = createStructuredSelector({
    isApp: selectPlatformSourceFromApp,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(PropertyItemProto);
