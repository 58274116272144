import React from "react";
import CustomFormatNumber from "components/CustomFormatNumber";
// import { symbol } from "d3-shape";

const FooterPropertyRow = ({ isMultiple = false, up, down, symbol = "%", symbolBeforeValue = "" }) => (
    <>
        <span className="text-12 primary-color fw-600">{up}</span>
        <span className="text-display text-no-break align-baseline">
            {symbolBeforeValue}
            {<CustomFormatNumber value={down} noSymbol fixed={isMultiple ? 1 : 2} />}{" "}
            <span className={`ml-2 ${isMultiple ? "text-18 fw-600" : "text-12 fw-500"}`}>{symbol}</span>
        </span>
    </>
);

export default FooterPropertyRow;
